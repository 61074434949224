import { SET_LOCATION, setLocation } from '../../../../common/store/location/location-actions';
import { ROUTE_HOME } from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { createUrlPattern } from '../../../../common/router/create-url-patter';
import { FEED_QUERY_PARAM_NAME } from '../constants';
import { FeedType } from '../models';

interface HeaderTabsState {
  activeTab: FeedType | null;
}

const initialState: HeaderTabsState = {
  activeTab: null,
};

export function headerTabs(state = initialState, action: typeof setLocation) {
  switch (action.type) {
    case SET_LOCATION:
      const payload = action.payload;
      return {
        activeTab: createUrlPattern(ROUTE_HOME).match(payload.pathname)
          ? payload.query[FEED_QUERY_PARAM_NAME] || null
          : state.activeTab,
      };
    default:
      return state;
  }
}
